import Modal from '@material-ui/core/Modal'
import React, { FC } from 'react'

import { SPUN_PEARL_GREY } from 'constants/colors'
import { CrossIcon } from 'uiKit/icons/CrossIcon'
import SubmitButton from 'uiKit/buttons/SubmitButton'

import classes from './styles.module.scss'
import { BaseButton } from 'uiKit/buttons/BaseButton/BaseButton'

export type WebsiteSource = {
  sourceId: string
  url: string
  _id?: string
}

interface DeleteSubPageModalProps {
  loading: boolean
  open: boolean
  url: string
  onClose: () => void
  onDelete: () => void
}

export const DeleteSubPageModal: FC<DeleteSubPageModalProps> = ({ url, loading, open, onClose, onDelete }) => {
  const handleLinkClick = (event, url: string) => {
    event.preventDefault()
    window.open(url, '_blank')
  }
  return (
    <Modal open={open} onClose={onClose}>
      <div className={`${classes.container} ${classes.deleteSubPageContainer}`}>
        <p className={classes.title}>Are you sure you want to delete subpage?</p>
        <div className={classes.closeButton} onClick={onClose}>
          <CrossIcon color={SPUN_PEARL_GREY} />
        </div>
        <a className={classes.subpageWrap} href={url} onClick={e => handleLinkClick(e, url)}>
          {url}
        </a>
        <div className={classes.buttonsWrap}>
          <BaseButton title="Delete" onClick={onDelete} disabled={loading} color="secondary" />
          <SubmitButton disabled={loading} onClick={onClose} title="Cancel" />
        </div>
      </div>
    </Modal>
  )
}
