import React from 'react'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  button: {
    height: '48px',
    borderRadius: '10px',
    border: 'none',
    fontWeight: 400,
    lineHeight: 'normal',
    fontSize: '17px',
    textAlign: 'center',
    padding: '0 25px',
    color: theme.tabs.login.buttonText(),
    cursor: 'pointer',
    background: theme.tabs.login.buttonBackground(),
    boxShadow: '0 3px 9px rgba(19, 69, 186, 0.206267)',
    '&:hover': {
      transform: 'scale(1.05)',
      transition: '.1s ease-in-out',
    },
  },
})

const Button = ({ type, title, onClick, classes }) => {
  return (
    <button className={classes.button} onClick={onClick}>
      {title}
    </button>
  )
}

export default withStyles(styles, { withTheme: true })(Button)
