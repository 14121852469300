import React, { useState } from 'react'
import { connect } from 'react-redux'

import Heading from '../../../../uiKit/texts/Heading'
import RadioButtonGroup from './RadioButtonGroup'

import classes from './styles.module.scss'
import { WidgetSettingsType, AiChatSettingsType } from '../../../../models/WidgetSettingsTypes'
import { WidgetIntegrationSubTabMap } from 'tabs/widget/constants'
import WidgetSubtab from './WidgetSubtab'
import AiChatSubtab from './AiChatSubtab'
import Paragraph from '../../../../uiKit/texts/Paragraph'
import { SPUN_PEARL_GREY } from '../../../../constants/colors'

interface Props {
  activeBot: any
  widgetSettings: WidgetSettingsType
  aiChatSettings: AiChatSettingsType
}

const IntegrationTab: React.FC<Props> = ({ activeBot, widgetSettings, aiChatSettings }) => {

  const [activeTab, setActiveTab] = useState(WidgetIntegrationSubTabMap.WIDGET)

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Heading>Integration</Heading>
      </div>
      <Paragraph style={{ color: SPUN_PEARL_GREY }}>Connect your chatbot to the website you own</Paragraph>

      <RadioButtonGroup value={activeTab} onChange={setActiveTab} />
      {activeTab === WidgetIntegrationSubTabMap.WIDGET &&
      <WidgetSubtab activeBot={activeBot} widgetSettings={widgetSettings}/>}
      {activeTab === WidgetIntegrationSubTabMap.AI_CHAT &&
      <AiChatSubtab activeBot={activeBot} aiChatSettings={aiChatSettings}/>}
    </div>
  )
}

const mapStateToProps = (state: {
  widgetSettings: WidgetSettingsType,
  activeBot: any,
  aiChatSettings: AiChatSettingsType,
}) => ({
  widgetSettings: state.widgetSettings,
  activeBot: state.activeBot,
  aiChatSettings: state.aiChatSettings,
})

export default connect(mapStateToProps)(IntegrationTab)
