/**
 * Created by Admin on 02.02.2018.
 */
import React from 'react'
import Select from 'react-select'
import { Button, withStyles } from '@material-ui/core'
import './Atom.css'
import '../components/messages/Messages.css'
import Modal from '@material-ui/core/Modal'
import NewMessage from './NewMessage/NewMessage'
import { getAtom, saveAtom } from '../api/atom'
import AtomNLPModal from '../../nlp/components/AtomNLPModal'
import { connect } from 'react-redux'
import { Prompt, withRouter } from 'react-router-dom'
import { DownIcon, UpIcon } from '../../../uiKit/icons/Icons'
import 'array.prototype.move'
import { alertError } from '../../../api'
import TagAction from './messages/TagAction'
import { loadTags } from '../api/tag'
import uuidv1 from 'uuid/v1'
import { getBotLanguages } from '../../settings/api/settings'

import { randomRedirectInitialState, saveUserInput, setAttributeMessage } from './messages/AvailableMessages'
import { isStringEmpty } from '../../../helpers/isStringEmpty'
import MessagesWrap from '../components/MessagesWrap/MessagesWrap'
import { getAttributes } from '../../settings/api/attributes'
import { deepCopyFunction } from '../../../helpers/deepCopyFunction'
import { formatAtomSave } from '../../../helpers/formatAtomSave'
import {
  SAVE_USER_INPUT_TYPE,
  SET_ATTRIBUTE_TYPE,
  TEXT_TYPE,
  CONDITIONAL_REDIRECT_TYPE,
  API_CALL_TYPE,
  RANDOM_REDIRECT_TYPE,
} from '../constants/messageTypes'
import { expressionValidation } from '../../../helpers/expressionValidation'

const styles = theme => ({
  container: {
    position: 'relative',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    marginRight: '-17px',
    padding: '40px 40px 00px',
    height: 'calc(100vh - 234px)',
    width: '100%',
  },
  warning: {
    width: 42,
    height: 26,
    marginTop: 18,
  },
  headerBlock: {
    padding: '15px 40px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerSelectorAndButton: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    minWidth: '40%',
    height: '100%',
  },
  headerButton: {
    margin: '5px',
    background: theme.tabs.atomEditor.saveAtomButton(),
    boxShadow: '0px 3px 9px rgba(19, 69, 186, 0.206267)',
    color: 'white',
    borderRadius: 10,
    height: 45,
  },
  root: {
    background: theme.tabs.atomEditor.background,
    boxShadow: '4px 4px 29px rgba(19, 69, 186, 0.0855129)',
  },
  input: {
    border: 'none',
    outline: 'none',
    borderRadius: '5px',
    fontSize: '14px',
    lineHeight: '19px',
    backgroundColor: 'white',
  },
  inputHolder: {
    border: '1px solid #C7CAD6',
    width: 'max-content',
    borderRadius: '10px',
    display: 'flex',
    transition: '1s all ease',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    marginRight: 10,
    padding: 10,
    margin: 5,
    maxHeight: 40,
  },
  '@global': {
    '.element:hover>.text_element': {
      opacity: '1',
      transition: '0.5s all ease',
    },
    '.element:hover rect': {
      fill: '#1658F3',
      transition: '0.5s all ease',
    },
    '.element:hover path': {
      stroke: '#1658F3',
      transition: '0.5s all ease',
    },
    '.element>.text_element': {
      opacity: '0',
      transition: '0.5s all ease',
    },
  },
})

class AtomEditor extends React.Component {
  handleChange = event => {
    const atom = { ...this.state.atom }
    atom.name = event.target.value
    this.setState({
      atom: atom,
    })
  }
  handleOpen = () => {
    if (!this.state.atom.isHardCoded) {
      this.setState({ open: true })
    }
  }
  handleClose = () => {
    if (this.state.isNameOk) this.setState({ open: false })
  }
  handleOpenAtomNLPModal = () => {
    this.setState({ openNlp: true })
  }
  handleCloseAtomNLPModal = () => {
    this.setState({ openNlp: false })
  }

  constructor(props) {
    super(props)
    this.scrollBlock = React.createRef()
    this.state = {
      open: false,
      isNameOk: true,
      name: '',
      openNlp: false,
      languageOptions: [],
      saveTime: new Date(),
      selectedLanguageOption: {
        value: 'EN',
        label: 'English',
        rtl: false,
      },
    }
  }

  componentDidMount() {
    // eslint-disable-next-line max-len
    getAtom(this.props.match.params.atomId, this.state.selectedLanguageOption.value, this.props.match.params.botId, atom => {
      this.setState({ atom: JSON.parse(JSON.stringify(atom)) })
      loadTags(this.props.match.params.botId)
      this.setLanguageOptions(this.props.match.params.botId)
    })
  }

  componentDidUpdate = () => {
    if (this.checkAtomForEditing()) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined
    }
  }

  componentWillUnmount() {
    window.onbeforeunload = undefined
  }

  setLanguageOptions = () => {
    getBotLanguages(this.props.activeBot.id)
      .then(botLanguages => {
        if (botLanguages?.defaultLanguage) {
          const { defaultLanguage, activeLanguages } = botLanguages
          const selectedOption = {
            label: defaultLanguage.fullName,
            value: defaultLanguage.shortName,
            rtl: defaultLanguage.isRtl,
          }

          const languageOptions = activeLanguages.map(language => {
            return {
              label: language.fullName,
              value: language.shortName,
              rtl: language.isRtl,
            }
          })

          this.setState({
            languageOptions: languageOptions,
            selectedLanguageOption: selectedOption,
          })
          return selectedOption
        }
      })
      .then(selectedOption => {
        getAttributes(this.props.match.params.botId, selectedOption.value)
      })
  }

  handleChangeName(text) {
    const atom = { ...this.state.atom }
    atom.name = text
    this.setState({
      atom: atom,
    })
    // eslint-disable-next-line max-len
    if ((this.props.atom.name !== text && this.getAtomNames().includes(text)) || text.trim() === '' || text.includes('/')) {
      this.setState({ isNameOk: false })
    } else {
      this.setState({ isNameOk: true })
    }
  }

  getAtomNames() {
    const names = []
    if (this.props.flows) {
      this.props.flows.forEach(flow => {
        flow.atoms != null &&
          flow.atoms.forEach(atom => {
            names.push(atom.name)
          })
      })
    }
    return names
  }

  validateMessages() {
    let isValid = true

    if (!this.state.atom.messages) return isValid

    for (const message of this.state.atom.messages) {
      switch (message.type) {
      case TEXT_TYPE: {
        isValid = this.validateTextMessages(message)
        break
      }
      case SET_ATTRIBUTE_TYPE: {
        isValid = this.validateSetAttribute(message)
        break
      }
      case SAVE_USER_INPUT_TYPE: {
        isValid = this.validateSaveUserInput(message)
        break
      }
      case API_CALL_TYPE: {
        isValid = this.validateApiCall(message)
        break
      }
      case CONDITIONAL_REDIRECT_TYPE: {
        isValid = this.validateSmartRedirect(message)
        break
      }
      case RANDOM_REDIRECT_TYPE: {
        isValid = this.validateRandomRedirect(message)
        break
      }
      }

      if (isValid && message?.buttons?.length) {
        isValid = this.validateButtons(message.buttons)
      }

      if (isValid && message?.quick_replies?.length) {
        isValid = this.validateReplies(message.quick_replies)
      }

      if (!isValid) {
        return isValid
      }
    }

    return isValid
  }

  validateTextMessages = message => {
    const error = message?.texts?.some(elem => isStringEmpty(elem))
    if (error) {
      alertError("You can't save an empty message.")
    }
    return !error
  }

  validateSetAttribute = message => {
    const error = message?.attributes?.some(elem => isStringEmpty(elem?.id?.toString()))
    if (error) {
      alertError('Please, fill in required fields.')
    }
    return !error
  }

  validateSaveUserInput = message => {
    const error =
      !this.isLastInTheArray(message) ||
      !message?.saveUserInput?.validation ||
      !message?.saveUserInput?.onValidationSuccessAtomId ||
      (message?.saveUserInput?.validation !== 'NONE' && !message?.saveUserInput?.onValidationFailAtomId) ||
      (message?.saveUserInput?.validation === 'CUSTOM' && !message?.saveUserInput?.customValidationRegex)
    if (error) {
      this.showErrorMessage(message, 'Save user input')
    }
    return !error
  }

  validateApiCall = message => {
    const error =
      !message?.apiCall?.url ||
      this.validateApiCallItems(message?.apiCall?.attributes) ||
      this.validateApiCallItems(message?.apiCall?.headers)
    if (error) {
      alertError('Please, fill in required fields.')
    }
    return !error
  }

  validateApiCallItems = items => {
    return items.some(item => {
      return (item.key && !item.value) || (item.value && !item.key)
    })
  }

  validateSmartRedirect = message => {
    const error =
      !this.isLastInTheArray(message) ||
      !message?.conditionalRedirect?.defaultRedirect ||
      message?.conditionalRedirect?.conditionGroups?.some(condition => {
        return (
          !condition?.redirectTo ||
          condition.expressions.some(expressionValidation)
        )
      })
    if (error) {
      this.showErrorMessage(message, 'Smart redirect')
    }
    return !error
  }

  validateRandomRedirect = message => {
    const error =
      !this.isLastInTheArray(message) ||
      message.randomRedirect?.randomAtoms.length === 0 ||
      message.randomRedirect?.randomAtoms?.some(randomAtom => {
        return randomAtom.atomId === undefined
      })
    if (error) {
      message.randomRedirect?.randomAtoms.length === 0
        ? alertError('Please, add at least one random atom')
        : this.showErrorMessage(message, 'Random redirect')
    }
    return !error
  }

  validateButtons = buttons => {
    return !buttons?.some(this.validateAttribute)
  }

  validateReplies = replies => {
    const errorTitle = replies?.some(qrItem => !qrItem.title)
    const errorPayload = replies?.some(qrItem => !qrItem.payload)
    const errorAttributes = replies?.some(this.validateAttribute)

    if (errorTitle) {
      alertError('Please, full titles for quick replies')
    }

    if (errorPayload) {
      alertError('Please, full next atom for quick replies')
    }

    return !errorTitle && !errorPayload && !errorAttributes
  }

  showErrorMessage(message, elementName) {
    if (!this.isLastInTheArray(message)) {
      alertError(
        `${elementName} element can be only the last in the atom, ` +
          'please move it down or delete all other elements.',
      )
    } else {
      alertError('Please, fill in required fields.')
    }
  }

  isLastInTheArray(message) {
    return this.state.atom.messages.indexOf(message) === this.state.atom.messages.length - 1
  }

  validateAttribute = item => {
    if (item?.attributes?.some(attribute => !attribute.id)) {
      alertError('Please, fill in required fields.')
      return true
    }
  }

  saveAtom() {
    let send = this.validateMessages()

    if (this.state.atom.randomAtoms && this.state.atom.randomAtoms.some(randomAtom => !randomAtom.atomName)) {
      alertError("You can't save an empty elements.")
      send = false
    }

    if (
      this.state.atom.messages?.length &&
      this.state.atom.messages?.every(message => message.type === SET_ATTRIBUTE_TYPE)
    ) {
      alertError('Set attribute cannot be a single element in the atom.')
      send = false
    }

    send &&
      saveAtom(
        formatAtomSave(this.state.atom),
        this.props.match.params.botId,
        this.state.selectedLanguageOption.value,
        atom => {
          this.setState({ atom: JSON.parse(JSON.stringify(atom)) })
        },
      )
  }

  static getDerivedStateFromProps(props, state) {
    if (state.atom && state.atom.id != props.match.params.atomId) {
      getAtom(props.match.params.atomId, state.selectedLanguageOption.value, props.match.params.botId)
      return {
        atom: props.atom,
        open: false,
        isNameOk: true,
      }
    }
    return null
  }

  deleteMessage = message => {
    const newAtom = { ...this.state.atom }
    newAtom.messages.splice(newAtom.messages.indexOf(message), 1)
    this.setState({ atom: newAtom })
  }

  addReaction = (index) => {
    const newAtom = { ...this.state.atom }
    newAtom.messages[index].enableReaction = true
    this.setState({ atom: newAtom })
  }

  removeReaction = (index) => {
    const newAtom = { ...this.state.atom }
    newAtom.messages[index].enableReaction = false
    this.setState({ atom: newAtom })
  }

  updateMessage = (message, index) => {
    const newAtom = { ...this.state.atom }
    newAtom.messages[index] = message
    this.setState({
      atom: newAtom,
    })
  }

  updateRedirect(redirectTo) {
    const newAtom = { ...this.state.atom }
    newAtom.redirectTo = redirectTo
    this.setState({
      atom: newAtom,
    })
  }

  updateTags(tags) {
    const newAtom = { ...this.state.atom }
    newAtom.tags = tags
    this.setState({
      atom: newAtom,
    })
  }

  moveToTop(oldIndex) {
    const newIndex = oldIndex - 1
    const atom = JSON.parse(JSON.stringify(this.state.atom))
    atom.messages.move(oldIndex, newIndex)
    this.setState({
      atom: atom,
    })
  }

  moveToBottom(oldIndex) {
    const newIndex = oldIndex + 1
    const atom = JSON.parse(JSON.stringify(this.state.atom))
    atom.messages.move(oldIndex, newIndex)
    this.setState({
      atom: atom,
    })
  }

  createNewMessage(message) {
    const newAtom = { ...this.state.atom }
    if (!newAtom.messages) newAtom.messages = []
    message['tempId'] = uuidv1()
    newAtom.messages.push(JSON.parse(JSON.stringify(message)))
    this.setState({
      atom: newAtom,
    })
  }

  addSetAttribute() {
    const newAtom = { ...this.state.atom }
    if (!newAtom.messages) newAtom.messages = []
    if (!newAtom.messages.some(message => message.type === SET_ATTRIBUTE_TYPE)) {
      const newMessage = deepCopyFunction(setAttributeMessage)
      newMessage['tempId'] = uuidv1()
      newAtom.messages.push(JSON.parse(JSON.stringify(newMessage)))
      this.setState({
        atom: newAtom,
      })
    }
  }

  addSaveUserInput() {
    const newAtom = { ...this.state.atom }
    if (!newAtom.messages) newAtom.messages = []
    if (!newAtom.messages.some(message => message.type === SAVE_USER_INPUT_TYPE)) {
      const newMessage = deepCopyFunction(saveUserInput)
      newMessage['tempId'] = uuidv1()
      newAtom.messages.push(JSON.parse(JSON.stringify(newMessage)))
      this.setState({
        atom: newAtom,
      })
    }
  }

  addRedirect() {
    if (!this.state.atom.randomAtoms) {
      const newAtom = { ...this.state.atom }
      newAtom.redirectTo = ''
      this.setState({
        atom: newAtom,
      })
    } else {
      alertError('Atom can contain only one of these elements: Redirect to atom, Random redirect')
    }
  }

  addTags() {
    const newAtom = { ...this.state.atom }
    newAtom.tags = []
    this.setState({
      atom: newAtom,
    })
  }

  addRandomRedirect = () => {
    if (this.state.atom.redirectTo === undefined || this.state.atom.redirectTo === null) {
      const newAtom = { ...this.state.atom }
      newAtom.randomAtoms = JSON.parse(JSON.stringify(randomRedirectInitialState.randomAtoms))
      newAtom.sendFirstAtomOnlyOnce = randomRedirectInitialState.sendFirstAtomOnlyOnce
      this.setState({
        atom: newAtom,
      })
    } else {
      alertError('Atom can contain only one of these elements: Redirect to atom, Random redirect')
    }
  }

  checkAtomForEditing() {
    const propsAtom = formatAtomSave(this.deepCopyFunction(this.props.atom))
    const stateAtom = formatAtomSave(this.deepCopyFunction(this.state.atom))

    return JSON.stringify(propsAtom) !== JSON.stringify(stateAtom)
  }

  deepCopyFunction = inObject => {
    let value, key
    if (typeof inObject !== 'object' || inObject === null) {
      return inObject // Return the value if inObject is not an object
    }
    // Create an array or object to hold the values
    const outObject = Array.isArray(inObject) ? [] : {}
    for (key in inObject) {
      value = inObject[key]
      // Recursively (deep) copy for nested objects, including arrays
      outObject[key] = typeof value === 'object' && value !== null ? this.deepCopyFunction(value) : value
    }

    return outObject
  }

  deleteTextsField(atom) {
    if (!atom || !atom.messages) return
    atom.messages.forEach(msg => {
      delete msg.texts
    })
    return atom
  }

  handleSelectedLanguage = languageOption => {
    getAtom(this.props.match.params.atomId, languageOption.value, this.props.match.params.botId, atom => {
      this.setState({ atom: JSON.parse(JSON.stringify(atom)), selectedLanguageOption: languageOption })
      loadTags(this.props.match.params.botId)
    })
  }

  onSaveAtom = () => {
    this.setState(
      {
        saveTime: new Date(),
      },
      () => this.saveAtom(),
    )
  }

  updateAtom = atom => {
    this.setState({ atom })
  }

  render() {
    const { classes } = this.props
    const { rtl } = this.state.selectedLanguageOption
    const nameBorder = !this.state.isNameOk ? '1px solid #FF624C' : '1px solid #C7CAD6'

    return (
      <div className={classes.root} style={{ height: '100%', position: 'fixed', width: '42%' }}>
        {this.state.atom && (
          <>
            <Prompt
              when={this.checkAtomForEditing()}
              message="Are you sure you want to leave this page without saving your current changes?"
            />
            <div key={this.state.atom.id}>
              <div className={classes.headerBlock}>
                <div
                  style={{
                    maxHeight: 55,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                  {this.state.open ? (
                    <div
                      className={classes.inputHolder}
                      style={{
                        display: 'block',
                        border: nameBorder,
                      }}>
                      <input
                        data-autotest={'atom-name-input'}
                        className={classes.input}
                        autoFocus
                        onBlur={() => this.handleClose()}
                        value={this.state.atom.name}
                        onKeyUp={this.checkEnterPress}
                        onChange={event => this.handleChangeName(event.target.value)}
                      />
                    </div>
                  ) : (
                    <p
                      style={{
                        fontWeight: 600,
                        lineHeight: '20px',
                        fontSize: 14,
                        color: '#3A3F62',
                      }}
                      onClick={() => this.handleOpen()}>
                      {this.state.atom.name}
                    </p>
                  )}
                </div>
                <div className={classes.headerSelectorAndButton}>
                  <div style={{ width: '220px', marginRight: '16px' }}>
                    <Select
                      options={this.state.languageOptions}
                      value={this.state.selectedLanguageOption}
                      onChange={this.handleSelectedLanguage}
                    />
                  </div>

                  <Button
                    disabled={!this.state.isNameOk}
                    className={classes.headerButton}
                    onClick={() => this.onSaveAtom()}>
                    Save
                  </Button>
                </div>

                <Modal
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                  open={this.state.openNlp}
                  onClose={this.handleCloseAtomNLPModal}>
                  <AtomNLPModal
                    atomId={this.state.atom.id}
                    atomName={this.state.atom.name}
                    closeAtomNLP={this.handleCloseAtomNLPModal}
                  />
                </Modal>
              </div>
              <div
                className={classes.container}
                style={{ overflowY: 'scroll', overflowX: 'hidden' }}
                ref={this.scrollBlock}>
                {this.state?.atom?.messages?.map((message, index) => (
                  <div
                    key={message.id || message.tempId}
                    style={{
                      position: 'relative',
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <div
                      style={{
                        cursor: 'pointer',
                        position: 'relative',
                        right: 20,
                      }}
                      key={index}>
                      {this.state.atom.messages.length > 1 && index != 0 && (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                          }}
                          className="element"
                          onClick={() => {
                            this.moveToTop(index)
                          }}>
                          <div className="text_element" style={{ fontSize: 12, textAlign: 'center' }}>
                            Move <br />
                            up
                          </div>
                          <div style={{ margin: '15px 0' }}>
                            <UpIcon />
                          </div>
                        </div>
                      )}
                      {this.state.atom.messages.length > 1 && index + 1 !== this.state.atom.messages.length && (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                          }}
                          onClick={() => this.moveToBottom(index)}
                          className="element">
                          <div style={{ margin: '10px 0' }}>
                            <DownIcon />
                          </div>
                          <div className="text_element" style={{ fontSize: 12, textAlign: 'center' }}>
                            Move <br />
                            down
                          </div>
                        </div>
                      )}
                    </div>
                    <MessagesWrap
                      message={message}
                      rtl={rtl}
                      index={index}
                      updateMessage={this.updateMessage}
                      deleteMessage={this.deleteMessage}
                      addReaction={this.addReaction}
                      removeReaction={this.removeReaction}
                      scrollBlock={this.scrollBlock}
                      saveTime={this.state.saveTime}
                      selectedLanguage={this.state.selectedLanguageOption}
                    />
                  </div>
                ))}
                {this.state.atom.tags != null && (
                  <TagAction
                    value={this.state.atom.tags}
                    onChange={value => this.updateTags(value)}
                    onDelete={() => this.updateTags(null)}
                  />
                )}
              </div>
              <NewMessage atom={this.state.atom} updateAtom={this.updateAtom} />
            </div>
          </>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  atom: state.atom,
  activeBot: state.activeBot,
  flows: state.flows,
})

export default withRouter(withStyles(styles)(connect(mapStateToProps)(AtomEditor)))
