import { CADET_BLUE, COMET_GREY, EGYPTIAN_BLUE, RIVER_BED } from 'constants/colors'
import styled from 'styled-components'

const Title = styled.h5`
  font-size: 14px;
  padding-top: 16px;
  margin-bottom: 0;
  color: ${COMET_GREY};
`

const DeleteWrap = styled.button<{ disabled: boolean }>`
  all: unset;
  cursor: pointer;
  padding: 0 8px;

  & svg path {
    fill: ${({ disabled }) => (disabled ? CADET_BLUE : RIVER_BED)};
  }
`

const Link = styled.a`
  text-decoration: none;
  font-size: 14px;
  padding: 12px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${EGYPTIAN_BLUE};
`

const Container = styled.div`
  max-height: 520px;
  overflow-y: auto;
`

const HideOverflow = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export { Container, Title, Link, HideOverflow, DeleteWrap }
