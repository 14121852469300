import React, { FC, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import Switch from '@material-ui/core/Switch'
import { withRouter } from 'react-router-dom'

import { getGreetingAnalytics, updateWidget } from 'tabs/widget/api/widget'
import { GreetingAnalytics } from 'tabs/widget/api/widget'
import Popover from 'uiKit/Popover'
import { EditIcon } from 'uiKit/icons/EditIcon'
import { DeleteIcon } from 'uiKit/icons/DeleteIcon'

import Table from '../../../../uiKit/table/Table'
import TableRow from '../../../../uiKit/table/TableRow'
import TableCell from '../../../../uiKit/table/TableCell'
import { ThreeIcon } from '../../../../uiKit/icons/ThreeIcon'
import { deepCopyFunction } from '../../../../helpers/deepCopyFunction'
import classes from './styles.module.scss'
import { GreetingType, WidgetSettingsType } from '../../../../models/WidgetSettingsTypes'
import { OptionItemType } from '../../../../models/MessageTypes'
import LoaderScreen from 'uiKit/loaders/loaderScreen'

const titles = [
  { title: 'Greeting name', maxWidth: 270 },
  { title: 'Sent', maxWidth: 120 },
  { title: 'Opened', maxWidth: 120 },
  { title: 'Enable/disable', maxWidth: 120 },
  { title: '', maxWidth: 120 },
]

interface GreetingsTableProps {
  greetings: GreetingType[]
  widgetSettings: WidgetSettingsType
  selectedLanguage: OptionItemType
  onToggleEditModal: (a: boolean, b: GreetingType) => void
  onToggleDeleteModal: (a: boolean, b: GreetingType) => void
  onToggleEditDefaultModal: (a: boolean, b: GreetingType) => void
  match: any
}

const GreetingsTable: FC<GreetingsTableProps> = ({
  greetings,
  widgetSettings,
  selectedLanguage,
  onToggleEditModal,
  onToggleDeleteModal,
  onToggleEditDefaultModal,
  match,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [loadingAnalytics, setLoadingAnalytics] = useState(false)
  const [greetingAnalytics, setGreetingAnalytics] = useState<GreetingAnalytics>(null)
  const [openedPopoverId, setOpenedPopoverId] = useState<null | number>(null)
  const widgetCopy = useMemo(() => deepCopyFunction(widgetSettings), [widgetSettings])
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  useEffect(() => {
    const botId = match.params.botId
    const body = greetings.map(greeting => greeting.hash)

    setLoadingAnalytics(true)
    getGreetingAnalytics(botId, body)
      .then(res => setGreetingAnalytics(res))
      .finally(() => setLoadingAnalytics(false))
  }, [selectedLanguage])

  const handleSwitch = (index: number) => {
    widgetCopy.languageConfigs[selectedLanguage.value].greetings[index].enabled =
      !widgetCopy.languageConfigs[selectedLanguage.value].greetings[index].enabled
    updateWidget(match.params.botId, widgetCopy)
  }

  const handleOpenPopover = (event: any, index: number) => {
    setOpenedPopoverId(index)
    setAnchorEl(event.currentTarget)
  }

  const handleClosePopover = () => {
    setAnchorEl(null)
    setOpenedPopoverId(null)
  }

  const handleEditDefaultGreeting = (greeting: GreetingType, index: number) => {
    greeting.index = index
    onToggleEditDefaultModal(true, greeting)
    handleClosePopover()
  }

  const handleEditGreeting = (greeting: GreetingType, index: number) => {
    greeting.index = index
    onToggleEditModal(true, greeting)
    handleClosePopover()
  }

  const handleDeleteGreeting = (greeting: GreetingType, index: number) => {
    greeting.index = index
    onToggleDeleteModal(true, greeting)
    handleClosePopover()
  }

  return (
    <>
      {loadingAnalytics ? (
        <LoaderScreen />
      ) : (
        <Table titles={titles}>
          {greetings?.map((greeting: GreetingType, index: number) => (
            <TableRow key={greeting.hash} index={index}>
              <TableCell style={{ maxWidth: 270 }}>{greeting.name}</TableCell>
              <TableCell style={{ maxWidth: 120 }}>{greetingAnalytics?.[greeting.hash]?.sent || 0}</TableCell>
              <TableCell style={{ maxWidth: 120 }}>{greetingAnalytics?.[greeting.hash]?.opened || 0}</TableCell>
              <TableCell style={{ maxWidth: 120 }}>
                <Switch color="primary" checked={greeting.enabled} onChange={() => handleSwitch(index)} />
              </TableCell>
              <TableCell style={{ maxWidth: 120, flexDirection: 'row-reverse' }}>
                {!greeting.isDefault ? (
                  <div className={classes.button} onClick={event => handleOpenPopover(event, index)}>
                    <ThreeIcon aria-describedby={id} variant="contained" color="primary" />
                  </div>
                ) : (
                  <div className={classes.button} onClick={() => handleEditDefaultGreeting(greeting, index)}>
                    Edit
                  </div>
                )}
              </TableCell>
              <Popover id={id} open={openedPopoverId === index} anchorEl={anchorEl} onClose={handleClosePopover}>
                <div>
                  <div className={classes.editButton} onClick={() => handleEditGreeting(greeting, index)}>
                    <EditIcon /> Edit
                  </div>
                  <div className={classes.deleteButton} onClick={() => handleDeleteGreeting(greeting, index)}>
                    <DeleteIcon /> Delete
                  </div>
                </div>
              </Popover>
            </TableRow>
          ))}
        </Table>
      )}
    </>
  )
}

const mapStateToProps = (state: { widgetSettings: WidgetSettingsType }) => ({
  widgetSettings: state.widgetSettings,
})

export default withRouter(connect(mapStateToProps)(GreetingsTable))
