import React, { FC, useState, ChangeEvent, Dispatch, SetStateAction } from 'react'
import Tooltip from '@material-ui/core/Tooltip'

import Search from 'uiKit/Search/Search'
import { NewDeleteIcon } from 'uiKit/icons/NewDeleteIcon'

import * as S from './SourcesSubPages.style'
import { WebsiteSource } from './DeleteSubPageModal'
import classes from './styles.module.scss'
import { HAWKES_BLUE, SELAGO_GRAY } from 'constants/colors'

const searchStyle = { maxWidth: '420px' }
const inputStyle = { minHeight: '46px', borderRadius: '10px' }

type WebsiteSubPage = {
  url: string
  _id?: string
}

interface SourcesSubPagesProps {
  title: string
  subPages: WebsiteSubPage[]
  sourceId: string
  setDeleteSubPageConfirmationModal: Dispatch<SetStateAction<{ source: WebsiteSource; isOpen: boolean }>>
  parentRowColor: string
}

export const SourcesSubPages: FC<SourcesSubPagesProps> = ({
  title,
  subPages,
  sourceId,
  setDeleteSubPageConfirmationModal,
  parentRowColor,
}) => {
  const [searchSubPage, setSearchSubPage] = useState('')

  const filteredSubPages = subPages.filter(({ url }) => url.toLowerCase().includes(searchSubPage.toLowerCase()))

  const handleSearch = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setSearchSubPage(target.value)
  }

  const handleLinkClick = (event, url: string) => {
    event.preventDefault()
    window.open(url, '_blank')
  }

  const getSubRowBackground = (parentRowColor: string, index: number): string => {
    if (parentRowColor === SELAGO_GRAY) {
      return index % 2 !== 0 ? HAWKES_BLUE : SELAGO_GRAY
    }
    return index % 2 !== 0 ? SELAGO_GRAY : HAWKES_BLUE
  }

  return (
    <>
      <Search style={searchStyle} inputStyle={inputStyle} onChange={handleSearch} />
      <S.Container>
        <S.Title>{title}</S.Title>
        {filteredSubPages.map(({ url, _id }, index) => (
          <div
            key={url}
            style={{
              flex: '1 1 100%',
              minWidth: 0,
              display: 'grid',
              gap: '12px',
              backgroundColor: getSubRowBackground(parentRowColor, index),
            }}>
            <S.HideOverflow>
              <S.Link key={url} onClick={event => handleLinkClick(event, url)} href={url}>
                {url}
              </S.Link>
              {_id ? (
                <S.DeleteWrap
                  disabled={Boolean(!_id)}
                  onClick={() => setDeleteSubPageConfirmationModal({ source: { sourceId, url, _id }, isOpen: true })}>
                  <NewDeleteIcon />
                </S.DeleteWrap>
              ) : (
                <Tooltip
                  id={url}
                  disableFocusListener
                  title="This subpage cannot be deleted"
                  placement="top-start"
                  classes={{ tooltip: classes.tooltip }}>
                  <div>
                    <S.DeleteWrap disabled={Boolean(!_id)}>
                      <NewDeleteIcon />
                    </S.DeleteWrap>
                  </div>
                </Tooltip>
              )}
            </S.HideOverflow>
          </div>
        ))}
      </S.Container>
    </>
  )
}
