/**
 * Created by Admin on 05.02.2018.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { createNewAtom } from '../api/atom'
import { PlusIcon } from '../../../uiKit/icons/Icons.js'
import { isStringEmpty } from '../../../helpers/isStringEmpty'
import { theme } from 'constants/theme'

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
  },
  addNew: {
    backgroundColor: theme.tabs.atoms.newAtomBackground,
    color: theme.tabs.atoms.newAtomText(),
    border: '1px solid',
    borderColor: theme.tabs.atoms.newAtomBorder(),
    borderRadius: 10,
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '5px 5px 5px 0',
    cursor: 'pointer',
  },
  input: {
    border: 'none',
    outline: 'none',
    borderRadius: '5px',
    fontSize: '14px',
    lineHeight: '19px',
    backgroundColor: 'white',
  },
  inputHolder: {
    border: '1px solid #B0B2C0',
    boxSizing: 'border-box',
    boxShadow: '0px 0px 6px rgba(215, 216, 223, 0.668054)',
    width: 'max-content',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    marginRight: 10,
    padding: 10,
    margin: 5,
    // maxHeight: 40,
  },
  '@global': {
    "[class*='NewFlow-paper']": {
      boxShadow: '4px 4px 29px rgba(19, 69, 186, 0.14)',
      borderRadius: 10,
    },
    '.buttonHover2:hover': {
      color: '#1658F3',
      background: 'white',
    },
    '.buttonHover2': {
      marginTop: 20,
      background: '#1658F3',
      color: 'white',
      borderRadius: 10,
    },
    "[class*='addNew'] svg": {
      position: 'relative',
      top: 2,
      right: 3,
    },
  },
})

class NewFlow extends React.Component {
  state = {
    open: false,
    usedNames: [],
    isNameOk: true,
    name: '',
    empty: false,
  }

  handleOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  handleChangeName(text) {
    this.setState({ name: text })
    if (this.state.usedNames.includes(text) || text.includes('/')) {
      this.setState({ isNameOk: false })
    } else {
      this.setState({ isNameOk: true })
    }
  }

  checkEnterPress = event => {
    if (event.key === 'Enter') {
      this.saveNewAtom()
    }
  }

  saveNewAtom() {
    if (!this.state.isNameOk) {
      return
    }
    if (!isStringEmpty(this.state.name)) {
      const newAtom = {
        name: this.state.name,
        flowId: this.props.flowId,
      }
      createNewAtom(newAtom, this.props.activeBotId)
      this.setState({ open: false, name: '', empty: false }, this.handleClose)
    } else {
      this.setState({ empty: true }, this.handleClose)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.flows) {
      const names = []
      nextProps.flows.forEach(flow => {
        flow.atoms != null &&
          flow.atoms.forEach(atom => {
            names.push(atom.name)
          })
      })
      this.setState({ usedNames: names })
    }
  }

  componentDidUpdate() {
    if (this.state.open && this.nameInput != null) this.nameInput.focus()
  }

  render() {
    const { classes } = this.props
    return (
      <div style={{ display: 'flex' }}>
        <div onClick={this.handleOpen} className={classes.addNew}>
          <PlusIcon height="10" width="10" color={theme.tabs.atoms.newAtomPlusIcon()} /> Add a new atom
        </div>
        {this.state.open && (
          <div
            className={classes.inputHolder}
            style={{
              display: 'block',
              border: '1px solid',
              borderColor: !this.state.isNameOk
                ? theme.tabs.atoms.newAtomInputBorder
                : theme.tabs.atoms.newAtomInputBorderError,
            }}>
            <input
              className={classes.input}
              placeholder="Atom Name"
              autoFocus
              onBlur={() => this.saveNewAtom()}
              value={this.state.name}
              onKeyUp={this.checkEnterPress}
              onChange={event => this.handleChangeName(event.target.value)}
            />
          </div>
        )}
      </div>
    )
  }
}

NewFlow.propTypes = {
  classes: PropTypes.object.isRequired,
}

const NewFlowExport = withStyles(styles)(NewFlow)

export default NewFlowExport
