import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import uuidv1 from 'uuid/v1'
import { EnterIcon } from '../../../uiKit/icons/Icons'
import Url from './Url'
import { isStringEmpty } from '../../../helpers/isStringEmpty'
import { urlValidation } from '../../../helpers/urlValidation'

const styles = theme => ({
  input: {
    border: 'none',
    outline: 'none',
    width: '100%',
    borderRadius: '5px',
    fontSize: '14px',
    lineHeight: '19px',
    backgroundColor: 'white',
    color: theme.defaults.text,
    '&::placeholder': {
      color: theme.defaults.grayColor,
    },
  },
  red: {
    border: '1px solid #FF624C',
    color: '#FF624C',
    width: 'max-content',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    marginRight: 10,
    padding: 10,
    maxHeight: 45,
    minWidth: '100%',
  },
  inputHolder: {
    border: '1px solid #C7CAD6',
    width: 'max-content',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    marginRight: 10,
    padding: '10px 20px',
    maxHeight: 45,
    minWidth: '100%',
  },
  disabled: {
    border: '1px solid white',
    marginRight: 10,
    maxHeight: 45,
  },
  userSays: {
    fontSize: 14,
    color: '#1658F3',
    margin: '18px 0',
  },
  none: {
    display: 'none',
  },
  alert: {
    color: '#FF624C',
    marginBottom: '-10px',
    fontSize: 12,
  },
})

class Urls extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errorIndex: null,
      editInput: null,
      showNotification: false,
      invalidValue: null,
      valid: false,
    }
  }

  uniqueFind = (i, value) =>
    i.trim().toUpperCase() === value.trim().toUpperCase()

  checkIfUrlUnique = (item, text, current) => {
    const value = text.trim().toUpperCase()
    return (
      item.trim().toUpperCase() === value &&
      value !== current.trim().toUpperCase()
    )
  }

  editWebUrl(id, value) {
    const webUrls = [...this.props.websiteUrl]
    if (
      !urlValidation(value) ||
      webUrls.some(i => this.checkIfUrlUnique(i, value, webUrls[id]))
    ) {
      this.setState({
        invalidValue: value,
        errorIndex: id,
      })
    } else {
      if (this.state.errorIndex !== null) {
        this.setState({
          errorIndex: null,
        })
      }
      webUrls[id] = value
      this.modifyInput(null, this.props.handleChange(webUrls))
    }
  }

  deleteWebUrl(index) {
    const newWebsiteUrl = [...this.props.websiteUrl]
    newWebsiteUrl.splice(index, 1)
    this.modifyInput(null, this.props.handleChange(newWebsiteUrl))
  }

  handleChangeInput(e) {
    this.setState({
      valid: !isStringEmpty(e.target.value),
      text: e.target.value,
    })
  }

  pushUrl(websiteUrl) {
    const urls = [...this.props.websiteUrl]
    urls.push(websiteUrl)
    this.setState(
      {
        text: '',
        showInvalid: false,
        showNotification: false,
        valid: false,
      },
      this.props.handleChange(urls),
    )
  }

  addUrl = () => {
    const { text } = this.state
    if (isStringEmpty(text)) return
    if (!urlValidation(text)) {
      this.setState({
        invalidValue: text,
        showInvalid: true,
        showNotification: false,
      })
      return
    }
    if (!this.checkUnique(text)) {
      this.pushUrl(text)
    } else {
      this.setState({
        invalidValue: text,
        showInvalid: false,
        showNotification: true,
      })
    }
  }

  checkUnique(value) {
    if (this.props.websiteUrl.length === 0) return false
    return this.props.websiteUrl.some(i => this.uniqueFind(i, value))
  }

  modifyInput(id, callback) {
    this.setState(
      {
        editInput: id,
        errorIndex: null,
      },
      () => {
        callback && callback()
      },
    )
  }

  render() {
    const { classes, websiteUrl } = this.props
    const { valid, invalidValue } = this.state
    return (
      <div style={{ width: 400 }}>
        <div className={classes.inputHolder}>
          <input
            maxLength={255}
            className={`${classes.input} addUrl`}
            value={this.state.text || ''}
            placeholder="Type your website URL by pressing Enter"
            onKeyPress={event => {
              if (
                (event.type === 'keypress' && event.charCode === 13) ||
                event.type === 'click'
              ) {
                this.addUrl()
              }
            }}
            onChange={event => this.handleChangeInput(event)}
          />
          <div
            onClick={e => {
              this.addUrl(e)
            }}
            className={`${classes.enter} enter`}>
            <EnterIcon
              width={14}
              height={14}
              color={valid ? '#11C314' : '#B0B2C0'}
            />
          </div>
        </div>
        {this.state.showNotification && (
          <p className={classes.alert}>
            {`Website with url '${invalidValue}' already added`}
          </p>
        )}
        {this.state.showInvalid && (
          <p className={classes.alert}>{`Url '${invalidValue}' is invalid`}</p>
        )}
        <div
          style={{
            overflowY: 'scroll',
            maxHeight: 200,
            paddingTop: 8,
            margin: '0 0 20px',
          }}>
          {websiteUrl &&
            websiteUrl.map((url, id) => (
              <Url
                key={uuidv1()}
                url={url}
                focus={this.state.editInput === id}
                modifyInput={id => this.modifyInput(id)}
                editWebUrl={(id, value) => this.editWebUrl(id, value)}
                deleteWebUrl={(id, e) => this.deleteWebUrl(id, e)}
                index={id}
                invalidValue={invalidValue}
                error={id === this.state.errorIndex}
              />
            ))}
        </div>
      </div>
    )
  }
}

Urls.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withRouter(withStyles(styles)(Urls))
