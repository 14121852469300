import React, { useEffect, useState } from 'react'

import * as S from './FileTab.style'
import { UploadForm } from '../UploadForm/UploadForm'
import { TrainingStatus } from '../../../../settings/constants/trainingStatus'
import {
  deleteKnowledgeSources,
  getKnowledgeSources,
  trainKnowledgeSources,
  updateFileKnowledgeSources,
} from 'tabs/nlp/api/websiteKnowledgeBase'
import { alertSuccess, alertError } from 'api'
import SourcesTable from '../SourcesTable'
import { ONE_MB } from 'constants/dataSize'
import { onTrainSuccessAlertMessage } from '../TextTab/TextTab'

export const FileTab = ({ activeBot }) => {
  const [controller, setController] = useState(new AbortController())
  const [sources, setSources] = useState([])
  const [trainingStatus, setTrainingStatus] = useState(TrainingStatus.PENDING)

  useEffect(() => {
    getKnowledgeSources(activeBot.id, 'FILE').then(res => {
      const trainingStatus = res?.sources?.some(source => source.status === TrainingStatus.PENDING)
        ? TrainingStatus.PENDING
        : TrainingStatus.READY
      setSources(res?.sources || [])
      setTrainingStatus(trainingStatus)
    })
    return () => {
      controller.abort()
      setController(new AbortController())
    }
  }, [])

  const handleUpload = event => {
    const file = event.target.files[0]
    const isNotUniqueFile = sources.some(
      source => source.source?.split('/').pop() === file?.name?.replace(/[^a-zA-Z0-9._]/g, ''),
    )
    const isTooBig = file?.size > ONE_MB * 100
    if (isNotUniqueFile) {
      alertError('This file is already added')
    } else if (isTooBig) {
      alertError('The file is too big to upload. Try uploading smaller file')
    } else {
      const formData = new FormData()
      formData.append('file', file)
      const newSource = {
        source: file?.name,
        status: 'NOT_TRAINED',
        type: 'FILE',
        isUploaded: false,
      }
      setSources([...sources, newSource])

      updateFileKnowledgeSources(activeBot.id, formData, controller.signal)
        .then(res => {
          setSources([...sources, res])
        })
        .then(() => alertSuccess('File is added successfully'))
        .catch(error => alertError(error?.message || 'Something went wrong'))
    }
  }

  const handleDelete = source => {
    if (source.isUploaded === false) {
      controller.abort()
      setController(new AbortController())
      const sourcesCopy = sources.filter(x => x !== source)
      setSources(sourcesCopy)
      return new Promise(() => alertSuccess('Uploading is canceled successfully'))
    }
    return deleteKnowledgeSources(activeBot.id, source._id)
      .then(() => {
        const sourcesCopy = sources.filter(x => x._id !== source._id)
        setSources(sourcesCopy)
      })
      .then(() => alertSuccess('File is deleted successfully'))
  }

  const handleTrain = () => {
    const body = sources.filter(x => ['NOT_TRAINED', 'FAILED'].includes(x.status)).map(x => x._id)

    trainKnowledgeSources(activeBot.id, body)
      .then(() => {
        setTrainingStatus(TrainingStatus.PENDING)
        alertSuccess(onTrainSuccessAlertMessage)
      })
      .catch(error => alertError(error?.message || 'Something went wrong'))
  }

  return (
    <S.FileTab>
      <UploadForm onUpload={handleUpload} trainingStatus={trainingStatus} />
      <SourcesTable sources={sources} onDelete={handleDelete} onTrain={handleTrain} trainingStatus={trainingStatus} />
    </S.FileTab>
  )
}
