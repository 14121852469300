import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  container: {
    position: 'relative',
    width: '100%'
  },
  input: {
    outline: 'none',
    width: '100%',
    padding: '14px 24px',
    borderRadius: 10,
    fontSize: '14px',
    lineHeight: '19px',
    color: theme.defaults.text,
    border: '1px solid #C7CAD6',
    maxHeight: 45,
    position: 'relative',
  },
  inputTitle: {
    fontSize: 14,
    color: theme.defaults.headerText,
    fontWeight: 400,
    margin: '24px 0 8px',
  },
  error: {
    borderColor: theme.defaults.error,
  },
  errorMessage: {
    fontSize: 12,
    position: 'absolute',
    color: theme.defaults.error,
    margin: 0,
  },
})

const Input = props => {
  const {
    classes,
    title,
    autofocus,
    inputTitleStyles,
    maxLength,
    style,
    value,
    placeholder,
    onChange,
    handleKeyPress,
    handleEnterPress,
    error,
    onBlur,
    onKeyDown,
    errorMessage,
    disabled
  } = props

  const handleKeyPressHandler = e => {
    if (e.key === 'Enter') {
      if (handleEnterPress) {
        handleEnterPress()
      }
    }
    if (handleKeyPress) {
      handleKeyPress(e)
    }
  }

  const handleBlur = e => {
    if (onBlur) onBlur(e)
  }

  const handleKeyDown = e => {
    if (onKeyDown) onKeyDown(e)
  }

  return (
    <div className={classes.container}>
      {title && (
        <p className={classes.inputTitle} style={inputTitleStyles}>
          {title}
        </p>
      )}
      <input
        className={`${classes.input} ${error && classes.error}`}
        style={style}
        value={value}
        autoFocus={autofocus}
        placeholder={placeholder}
        maxLength={maxLength}
        onChange={onChange}
        onKeyPress={handleKeyPressHandler}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        disabled={disabled}
      />
      {error && errorMessage && (
        <p className={classes.errorMessage}>
          {errorMessage}
        </p>
      )}
    </div>
  )
}

Input.defaultProps = {
  inputTitleStyles: {},
}

Input.propTypes = {
  classes: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired,
  inputTitleStyles: PropTypes.object,
  style: PropTypes.object,
  maxLength: PropTypes.number,
  title: PropTypes.string,
  error: PropTypes.bool,
  autofocus: PropTypes.bool,
  handleKeyPress: PropTypes.func,
  handleEnterPress: PropTypes.func,
}

export default withRouter(withStyles(styles)(Input))
