/**
 * Created by Admin on 16.02.2018.
 */
import React from 'react'
import QuickReply from './QuickReply'
import { OvalIcon } from '../../../../uiKit/icons/Icons'
import { Popover, withStyles } from '@material-ui/core'
import { theme } from 'constants/theme'

const styles = {
  container: {
    direction: 'inherit',
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    height: 53,
    display: 'flex',
    margin: '20px auto auto',
  },
  view: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'scroll',
    marginRight: -17,
    marginBottom: -17,
    display: 'flex',
  },
  threeIcon: {
    borderLeft: '1px solid',
    borderLeftColor: theme.tabs.atomEditor.quickReply.border,
    display: 'flex',
    flexDirection: 'column',
    padding: '7px 13px',
    justifyContent: 'space-between',
    width: 35,
  },
  replyItems: {
    display: 'flex',
    flexDirection: 'column',
  },
  replyItem: {
    fontSize: 14,
    color: '#3A3F62',
    padding: 16,
    cursor: 'pointer',
  },
  popover: {
    background: '#FFFFFF',
    border: '1px solid #D7D8DF',
    boxSizing: 'border-box',
    borderRadius: 10,
    boxShadow: 'none',
  },
  qrWrap: {
    display: '-webkit-box',
    overflow: 'scroll',
    marginTop: 10,
    '&::-webkit-scrollbar': {
      height: 6,
    },
    '&::-webkit-scrollbar-thumb': {
      opacity: 0.6,
      backgroundColor: 'rgba(99, 99, 99, 0.2)',
    },
  },
  addReply: {
    margin: '0 0 5px',
    minWidth: '186px',
    fontWeight: 500,
    textAlign: 'center',
    cursor: 'pointer',
    border: '1px solid',
    borderColor: theme.tabs.atomEditor.quickReply.addNewBorder,
    borderRadius: '50px',
    fontSize: '14px',
    lineHeight: '16px',
    height: '38px',
    display: 'flex',
  },
  addReplyText: {
    padding: '10px',
    color: theme.tabs.atomEditor.quickReply.addNewText,
  },
}

class MessageQuickReplies extends React.Component {
  state = {
    open: false,
    positionTop: 400,
    positionLeft: 200,
    anchorReference: 'anchorEl',
  }

  handleChange(reply, replyIndex) {
    const newMessage = JSON.parse(JSON.stringify(this.props.message))
    newMessage.quick_replies[replyIndex] = reply
    this.props.updateMessage(newMessage)
  }

  addNewReply(type) {
    const newMessage = JSON.parse(JSON.stringify(this.props.message))
    if (newMessage.quick_replies == null) {
      newMessage.quick_replies = []
    }
    if (type == undefined) {
      newMessage.quick_replies.push({
        title: '',
        content_type: 'text',
        payload: '',
      })
    } else {
      newMessage.quick_replies.push({
        content_type: type,
      })
    }

    this.props.updateMessage(newMessage)
  }

  focus() {
    this.setState({
      open: true,
    })
  }

  handleClose = () => {
    this.setState({
      open: false,
    })
  }

  deleteReply(replyIndex) {
    const newMessage = JSON.parse(JSON.stringify(this.props.message))
    newMessage.quick_replies.splice(replyIndex, 1)
    this.props.updateMessage(newMessage)
  }

  render() {
    const { open, anchorReference } = this.state
    const { classes, rtl } = this.props
    return (
      <div className={classes.qrWrap}>
        {this.props.message.quick_replies != null &&
          this.props.message.quick_replies.map((reply, replyIndex) => (
            <div style={{ display: 'flex' }} key={replyIndex}>
              <QuickReply
                reply={reply}
                onDelete={() => this.deleteReply(replyIndex)}
                onChange={reply => this.handleChange(reply, replyIndex)}
                rtl={rtl}
              />
            </div>
          ))}
        {(this.props.message.quick_replies == null ||
          this.props.message.quick_replies.length < 11) && (
          <div
            ref={node => {
              this.anchorEl = node
            }}
            className={classes.addReply}>
            <div className={classes.addReplyText} onClick={() => this.addNewReply()}>
              + Add Quick Reply
            </div>
            <div onClick={() => this.focus()} style={styles.threeIcon}>
              <OvalIcon color={theme.tabs.atomEditor.quickReply.ovalIcon} />
              <OvalIcon color={theme.tabs.atomEditor.quickReply.ovalIcon} />
              <OvalIcon color={theme.tabs.atomEditor.quickReply.ovalIcon} />
            </div>
          </div>
        )}
        <Popover
          open={open}
          onClose={this.handleClose}
          anchorEl={this.anchorEl}
          anchorReference={anchorReference}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          PaperProps={{
            style: {
              overflow: 'visible',
              borderRadius: 10,
            },
          }}>
          <div className={classes.popover}>
            <div className={classes.replyItems}>
              <div
                onClick={() => this.addNewReply('user_phone_number')}
                className={classes.replyItem}>
                Phone number
              </div>
              <div
                onClick={() => this.addNewReply('location')}
                className={classes.replyItem}>
                Location
              </div>
              <div
                onClick={() => this.addNewReply('user_email')}
                className={classes.replyItem}>
                Email
              </div>
            </div>
          </div>
        </Popover>
      </div>
    )
  }
}

export default withStyles(styles)(MessageQuickReplies)
