import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route } from 'react-router-dom'
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import './containers/index.css'
import App from './containers/App'
import reducer from './reducers/index'
import { MuiThemeProvider } from '@material-ui/core/'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'
import DateFnsUtils from 'material-ui-pickers/utils/date-fns-utils'
import * as Sentry from '@sentry/react'
import * as FullStory from '@fullstory/browser'
import { Integrations } from '@sentry/tracing'
import { theme } from 'constants/theme'
// eslint-disable-next-line no-unused-vars
import * as globalHelpers from './helpers'

const sentryReduxEnhancer = Sentry.createReduxEnhancer({})
export const store = createStore(reducer, sentryReduxEnhancer)

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV4Instrumentation(history),
      }),
    ],
    tracesSampleRate: 0,
  })
}

if (process.env.REACT_APP_FULL_STORY_ORG_ID) {
  FullStory.init({ orgId: process.env.REACT_APP_FULL_STORY_ORG_ID })
}

ReactDOM.render(
  <Provider store={store}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <BrowserRouter>
        <MuiThemeProvider theme={theme}>
          <Route path="/" component={App} />
        </MuiThemeProvider>
      </BrowserRouter>
    </MuiPickersUtilsProvider>
  </Provider>,

  document.getElementById('root') || document.createElement('div'),
)
