export const styles = theme => ({
  container: {
    width: 124,
    height: 36,
    backgroundColor: theme.tabs.support.assignToMeButton,
    color: theme.tabs.support.assignToMeButtonText,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    margin: '0px 12px',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
})
